<template>
  <div
    class='w-100'
  >
    <div class="py-3">
      <search-indicator
        v-show="searchText"
        @reset="resetSearch"
      >
        {{ searchText }}
      </search-indicator>
    </div>
    <b-table
      :fields="fields"
      :items='items'
      show-empty
      stacked="lg"
      striped
      class="stock-table"
    >
      <template #empty>
        <div class="text-center">
          {{ messages.getLabelNoDataFound() }}
        </div>
      </template>
      <template #cell(plannerGroups)="row">
        <span
          v-for="plannerGroup in row.item.plannerGroups"
          :key="plannerGroup"
        >
          {{ plannerGroup }}
          <br />
        </span>
      </template>
      <template #cell(workCenters)="row">
        <span
          v-for="workCenter in row.item.workCenters"
          :key="workCenter"
        >
          {{ workCenter }}
          <br />
        </span>
      </template>
      <template #cell(materialCode)="row">
        <b-link
          v-if="createMaterialCodeUrl(row.item.materialCode)"
          :href="createMaterialCodeUrl(row.item.materialCode)"
          target="_blank"
        >
          {{ row.item.materialCode }}
        </b-link>
        <span v-else>
          {{ row.item.materialCode }}
        </span>
      </template>
      <template #cell(quantity)="row">
        <div class="d-none d-lg-table">
          <span
            :id="row.item.rowId + '-quantity'"
            :aria-describedby="row.item.rowId + '-quantity-tooltip'"
            tabindex="0"
            class="tooltipped-text"
          >
            <span v-if="row.item.quantity > 0">
              {{ row.item.quantity }}
            </span>
            <b-badge v-else-if="row.item.quantity < 0">
              {{ "Stock Count on going" }}
            </b-badge>
            <b-badge v-else pill variant="warning">
              {{ messages.getLabelOutOfStock() }}
            </b-badge>
          </span>
          <b-tooltip
            v-if="row.item.quantity > 0"
            :id="row.item.rowId + '-quantity-tooltip'"
            :target="row.item.rowId + '-quantity'"
            triggers="hover focus"
          >
            <span
              v-for="quantityDetail in quantityDetailsFields"
              :key="quantityDetail.key"
            >
              {{ quantityDetail.label }}: {{ row.item[quantityDetail.key] }}
              <br />
            </span>
          </b-tooltip>
          <b-tooltip
            v-else-if="row.item.quantity < 0"
            :id="row.item.rowId + '-quantity-tooltip'"
            :target="row.item.rowId + '-quantity'"
            triggers="hover focus"
          >
            <span
              v-for="quantityDetail in quantityDetailsFields"
              :key="quantityDetail.key"
            >
              {{ quantityDetail.label }}: {{ "hidden" }}
              <br />
            </span>
          </b-tooltip>
        </div>
        <div class="d-lg-none">
          <details-widget arrow-class="float-right">
            <template v-slot:summary>
              <span v-if="row.item.quantity > 0">
                {{ row.item.quantity }}
              </span>
              <b-badge v-else-if="row.item.quantity < 0">
                {{ "Stock Count on going" }}
              </b-badge>
              <b-badge v-else pill variant="warning">
                {{ messages.getLabelOutOfStock() }}
              </b-badge>
            </template>
            <span
              v-for="quantityDetail in quantityDetailsFields"
              :key="quantityDetail.key"
            >
              <span v-if="row.item[quantityDetail.key] > 0">
                {{ quantityDetail.label }}: {{ row.item[quantityDetail.key] }}
              </span>
              <span v-if="row.item[quantityDetail.key] < 0">
                {{ quantityDetail.label }}: {{ "hidden" }}
              </span>
              <br />
            </span>
          </details-widget>
        </div>
      </template>
      <template v-slot:custom-foot class="desktop-pagination-container">
        <b-tr id="footer-tr" class="mx-auto">
          <b-td colspan="10"> <!--there is 10 visible columns in My Plant Stock so having colspan=10 positions the buttons nicely in other views too-->
            <div
              class="desktop-pagination-previous"
            >
              <b-button
                v-show="hasPrev"
                :disabled="ongoingQuery"
                squared
                class="text-uppercase text-primary"
                @click="previousPage"
              >
                {{ messages.getActionPreviousPage() }}
              </b-button>
            </div>
            <div
              class="desktop-pagination-next"
            >
              <b-button
                v-show="hasNext"
                :disabled="ongoingQuery"
                squared
                class="text-uppercase text-primary"
                @click="nextPage"
              >
                {{ messages.getActionNextPage() }}
              </b-button>
            </div>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <p v-show="searchText" class="text-center">
      <strong>{{ messages.getLabelNoteIntro() }}:</strong>
      {{ messages.getLabelZeroQuantityItemsHiddenWhenSearching() }}
    </p>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import DetailsWidget from '@/components/DetailsWidget'
import SearchIndicator from '@/components/Search/SearchIndicator'
import { MY_TEAM_STOCK, MY_PLANT_STOCK } from '@/constants/stock-management'
import { MAX_RESULTS, MOBILE_MAX_RESULTS } from '@/constants/shared-constants'
let RESIZE_OBSERVER = null
let INFINITE = null

export default {
  name: 'StockTable',
  components: { DetailsWidget, SearchIndicator },
  data () {
    return {
      mobileView: false,
      messages
    }
  },
  computed: {
    quantityDetailsFields () {
      const quantityDetailsFields = [
        { key: 'unrestrictedCount', label: messages.getFieldUnrestricted() },
        { key: 'transferCount', label: messages.getFieldTransfer() },
        { key: 'quantityInspectionCount', label: messages.getFieldInspection() },
        { key: 'restrictedCount', label: messages.getFieldRestricted() },
        { key: 'blockedCount', label: messages.getFieldBlocks() }
      ]
      return quantityDetailsFields
    },
    items () {
      return this.itemsRaw.map(item => {
        return {
          ...item,
          rowId: this.createRowId(item)
        }
      })
    },
    fields () {
      let fields = [
        { key: 'storageLocation', label: this.storageLocationLabel },
        { key: 'materialCode', label: messages.getFieldMaterialCode() },
        { key: 'materialDescriptionEnglish', label: messages.getFieldMaterialDescription() },
        { key: 'bin', label: messages.getFieldBin() },
        { key: 'quantity', label: messages.getFieldQuantity() },
        { key: 'reorderPoint', label: messages.getFieldRop(), headerTitle: messages.getFieldReorderPoint() },
        { key: 'reorderQuantity', label: messages.getFieldRoq(), headerTitle: messages.getFieldReorderQuantity() }
      ]

      const { type } = this.userSearchOptions

      // Add columns based of filtering:
      if (type === MY_TEAM_STOCK || type === MY_PLANT_STOCK) {
        fields.unshift({ key: 'workCenters', label: messages.getFieldWorkCenters() })
      }
      if (type === MY_PLANT_STOCK) {
        fields.unshift({ key: 'plannerGroups', label: messages.getFieldPlannerGroups() })
        fields.unshift({ key: 'plant', label: messages.getFieldPlant() })
      }

      // Add class for CSS:
      fields = fields.map(field => ({ ...field, tdClass: 'justified-td' }))

      return fields
    },
    searchText () {
      return this.$store.getters['stockManagement/searchParts'].join(' ')
    },
    itemsRaw () {
      if (this.mobileView) {
        return this.$store.getters['stockManagement/mobileStockItems']
      }
      return this.$store.getters['stockManagement/stockItems'](this.page)
    },
    rows () {
      return this.items.length
    },
    userSearchOptions () {
      return this.$store.getters['stockManagement/userSearchOptions']
    },
    ongoingQuery () {
      return this.$store.getters['stockManagement/ongoingQuery']
    },
    page: {
      get () {
        return this.$store.getters['stockManagement/page']
      },
      set (page) {
        this.$store.commit('stockManagement/setPage', page)
      }
    },
    lastPage () {
      return this.$store.getters['stockManagement/lastPage']
    },
    hasPrev () {
      return this.$store.getters['stockManagement/hasPrev']
    },
    hasNext () {
      return this.$store.getters['stockManagement/hasNext']
    },
    storageLocationCount () {
      return this.$store.getters['stockManagement/storageLocationCount']
    },
    storageLocationLabel () {
      let countText = ''
      if (typeof this.storageLocationCount === 'number') {
        countText = ` (${this.storageLocationCount})`
      }
      return `${messages.getFieldStorageLocation()}${countText}`
    }
  },
  methods: {
    createRowId (item) {
      return (
        `${item.plant}-${item.plannerGroups.join('-')}-${
          item.workCenters.join('-')}-${item.storageLocation}-${
          item.materialCode}`
      )
    },
    createMaterialCodeUrl (materialCode) {
      return this.$store.getters.createMaterialCodeUrl(materialCode)
    },
    resetSearch () {
      const queryParameters = {
        query: []
      }
      this.$store.dispatch('stockManagement/search', queryParameters)
    },
    nextPage () {
      if (this.page === this.lastPage.index) {
        if (!this.lastPage.hasNext) {
          throw Error('No pages to fetch')
        }
        // fetchNextPage should be used here
        this.$store.dispatch('stockManagement/fetchNextPage', { maxResults: MAX_RESULTS })
          .then(response => {
            this.page = this.lastPage.index
          })
      } else {
        this.page = this.page + 1
      }
    },
    previousPage () {
      if (this.page === 0) {
        // button should be disabled
      }
      // this should not do the query as the previous page is stored already
      this.page = this.page - 1
      // this.$store.commit('stockManagement/setPage', this.page - 1)
      // this.$store.dispatch('stockManagement/search', queryParameters)
    },
    // Add logic for mobile to fetch next page with infinite scroll
    infiniteScroll () {
      INFINITE = window.onscroll = () => {
        if (!RESIZE_OBSERVER) {
          this.observeFooterWidth()
        }
        // When footer is hidden its width is 0 and we are in mobileview
        const footerElement = document.getElementById('footer-tr')
        if (footerElement && footerElement.clientWidth === 0) {
          this.mobileView = true
          // console.log(document.documentElement.scrollTop + window.innerHeight, document.body.scrollHeight - window.innerHeight)
          const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.body.scrollHeight - window.innerHeight
          // When we reach the bottom of window we load more items from database
          if (bottomOfWindow && !this.ongoingQuery) {
            if (this.hasNext) {
              const viewWrapper = document.scrollingElement
              const scrollPosition = viewWrapper.scrollTop
              this.$store.dispatch('stockManagement/fetchNextPage', { maxResults: MOBILE_MAX_RESULTS }).then(() => {
                viewWrapper.scrollTop = scrollPosition
              })
            }
          }
        } else {
          // When footer-tr width is bigger than 0 it is visible and that means desktop mode is active
          this.mobileView = false
        }
      }
      INFINITE()
    },
    observeFooterWidth () {
      const footerElement = document.getElementById('footer-tr')
      if (footerElement) {
        RESIZE_OBSERVER = new ResizeObserver(function () {
          if (footerElement.clientWidth === 0) {
            this.mobileView = true
          } else {
            this.mobileView = false
          }
        })
        RESIZE_OBSERVER.observe(footerElement)
      }
    }
  },
  created () {
    // FIXME: Placeholder for loading mobile (and desktop) initial list
    if (this.$store.getters['stockManagement/firstFetchDone'] === false) {
      if (this.ongoingQuery) {
        // This happend when the route change comes from eg. search and not a
        // direct page load.
        return
      }
      let queryParameters
      if (this.$screen.width <= 991) {
        queryParameters = {
          query: [],
          maxResults: MOBILE_MAX_RESULTS
          // FIXME: Get defaults from query instead.
          // Check if this is "copy-pasted" or bookmarked url.
          // query: this.$router.currentRoute.query?.query ?? '',
          // page: this.$router.currentRoute.query?.page ?? 1
        }
      } else {
        queryParameters = {
          query: [],
          maxResults: MAX_RESULTS
        }
      }
      this.$store.dispatch('stockManagement/search', queryParameters)
    }
  },
  mounted () {
    this.observeFooterWidth()
    this.infiniteScroll()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.INFINITE)
    if (RESIZE_OBSERVER) {
      RESIZE_OBSERVER.disconnect()
      RESIZE_OBSERVER = null
    }
  }
}
</script>

<style scoped lang="scss">
.stock-table ::v-deep .justified-td::before {
  text-align: left !important;
}

.tooltipped-text:focus {
  outline: 0.2rem solid rgb(14 112 184 / 25%);
}

.desktop-pagination-container {
  display: flex;
  margin-bottom: 20px;
}
.desktop-pagination-previous {
  float: left;
}
.desktop-pagination-next {
  float: right;
}
#footer-tr {
  flex-direction: column;
}
</style>
