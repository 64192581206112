<template>
  <div>
    <top-panel-button
      @click="openMenu"
      aria-controls="status-filter-menu-dialog"
      aria-haspopup="dialog"
    >
      {{ dropdownTypeMenuTitle }}
      <i
        class="icon-size pl-2"
        :class="{ 'icon-chevron-up': showMenu, 'icon-chevron-down': !showMenu }"
      ></i>
    </top-panel-button>
    <bottom-dialog
      v-model="showMenu"
      id="status-filter-menu-dialog"
    >
      <pressure-button-selection
        type="mobile"
        v-model="type"
        :options="stockManagementButtons"
        class="w-100"
      />
    </bottom-dialog>
  </div>
</template>

<script>
/**
 * Mobile view only filter menu, where user can select all, arrived, incoming next and "only my orders".
 */

import getStockManagementButtons from '@/components/StockManagement/get-stock-management-buttons'
import BottomDialog from '@/components/TopPanel/BottomDialog'
import PressureButtonSelection from '@/components/FilterPanel/PressureButtonSelection'
import TopPanelButton from '@/components/TopPanel/TopPanelButton'
import { MOBILE_MAX_RESULTS } from '@/constants/shared-constants'

export default {
  name: 'StockFilterMenu',
  components: {
    BottomDialog,
    PressureButtonSelection,
    TopPanelButton
  },
  data () {
    return {
      // Updated when the filter menu is popped up and used to check
      // if user made any changes so that a new search can be issued.
      searchOptionsSnapshot: {},
      showMenu: false
    }
  },
  computed: {
    stockManagementButtons () {
      return getStockManagementButtons()
    },
    dropdownTypeMenuTitle () {
      return this.stockManagementButtons.find(button => button.value === this.type)?.label
    },
    showMyOrders () {
      return this.$store.getters.useMyOrders
    },
    type: {
      get () {
        return this.$store.getters['stockManagement/userSearchOptions'].type
      },
      set (type) {
        this.showMenu = false
        this.$store.commit('stockManagement/setSearchOptionType', type)
      }
    }
  },
  methods: {
    openMenu () {
      this.showMenu = true
    },
    searchIfFiltersChanged () {
      if (this.searchOptionsSnapshot?.type !== this.type) {
        // We could emit an event here...
        // this.$emit('filters-changed')
        const query = this.$store.getters['stockManagement/searchParts']
        this.$store.dispatch('stockManagement/search', { query, maxResults: MOBILE_MAX_RESULTS })
      }
    }
  },
  watch: {
    showMenu (isOpenNow) {
      if (isOpenNow) {
        // Store the state of filters when the menu is opened
        this.searchOptionsSnapshot = {
          type: this.type
        }
      } else {
        // TODO: Add smoother transition _TO CSS_ so that the hiding is not so sudden.
        // Make a new search if user changed something so that stale
        // data is not shown. This has to happen on next tick as the Vuex
        // getters have to be updated before the comparison to the snapshot.
        this.$nextTick().then(this.searchIfFiltersChanged)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-size {
  font-size: 14px;
}
</style>
