<template>
  <page-layout>
    <template v-slot:topBar>
      <top-panel class="d-block d-lg-none"/>
    </template>
    <filter-panel class="d-none d-lg-flex"/>
    <stock-table />
  </page-layout>
</template>

<script>
import { stockManagement } from '@/constants/permitted-actions'
import TopPanel from '../components/StockManagement/Mobile/StockManagementTopPanel'
import StockTable from '../components/StockManagement/StockTable'
import PageLayout from '../components/PageLayout'
import FilterPanel from '../components/StockManagement/Desktop/FilterPanel'

export default {
  name: 'StockManagement',
  components: { FilterPanel, PageLayout, TopPanel, StockTable },
  mounted () {
    if (!this.$store.getters.isPermitted(stockManagement.get)) {
      console.warn('User does not have permissions to access /stock-management')
      this.$router.push('/status-monitor')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
