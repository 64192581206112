<template>
  <details
    :open="isOpen"
    @toggle="onToggle"
    ref="details"
  >
    <summary class="details-widget-summary">
      <i
        :class="[
          { 'icon-chevron-down': isOpen, 'icon-chevron-right': !isOpen },
          arrowClass
        ]"
      />
      <slot name="summary">{{ messages.getFieldDetails() }}</slot>
    </summary>
    <slot></slot>
  </details>
</template>

<script>
import { messages } from '@/utils/strings'

/**
 * Styled version of `<details>` HTML element, that can be controlled with
 * `v-model`.
 *
 * You can give a summary label with: `v-slot:summary`.
 *
 * You can style the arrow with `arrow-class`.
 *
 * For example, you can have it be on the right with:
 * `arrow-class="float-right"`
 */
export default {
  name: 'DetailsWidget',
  props: {
    arrowClass: {
      type: [String, Array, Object],
      default: ''
    },
    value: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      innerValue: false,
      messages
    }
  },
  computed: {
    isOpen () {
      return this.value ?? this.innerValue
    }
  },
  methods: {
    onToggle () {
      this.innerValue = this.$refs.details.open
      this.$emit('input', this.$refs.details.open)
    }
  }
}
</script>

<style scoped lang="scss">
.details-widget-summary {
  list-style: none;

  &:focus {
    outline: 0.2rem solid rgb(14 112 184 / 25%);
  }
}
</style>
