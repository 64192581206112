<template>
  <div
    class="pt-3"
  >
    <span>
      {{ messages.getPageStatusFilter() }}:
    </span>
    <div
      class="d-flex align-items-center pt-2"
    >
      <pressure-button-selection
        type="desktop"
        v-model="type"
        :options="stockManagementButtons"
        class="w-100"
      />
    </div>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import getStockManagementButtons from '@/components/StockManagement/get-stock-management-buttons'
import PressureButtonSelection from '@/components/FilterPanel/PressureButtonSelection'

export default {
  name: 'SearchOptions',
  components: { PressureButtonSelection },
  data () {
    return {
      typeSnapshot: null,
      messages
    }
  },
  computed: {
    stockManagementButtons () {
      return getStockManagementButtons()
    },
    showMyOrders () {
      return this.$store.getters.useMyOrders
    },
    type: {
      get () {
        return this.$store.getters['stockManagement/userSearchOptions'].type
      },
      set (type) {
        this.searchOptionChanged('type', type)
        this.$store.commit('stockManagement/setSearchOptionType', type)
      }
    }
  },
  methods: {
    /**
     * Triggered when Type option is changed.
     *
     * @param {any} value - Value from the input element event.
     * @param {String} source - Which element was changed.
     */
    searchOptionChanged (source, value) {
      // console.log({ _: 'searchOptionChanged', source, value })
      //
      // In practise the source and value can be ignored. This is just to inform
      // the FilterPanel that we need to refrest the stock item list (by
      // making a new search).
      this.$nextTick().then(() => {
        // Because the Vuex store updates for the next tick, it safer to
        // emit the change in the next tick. And by safer I mean required.
        this.$emit('change', { source, value })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.no-wrap {
  white-space: nowrap;
}

.not-visible {
  visibility: hidden;
}

/* Show pointer cursor for checkbox */
.clickable {
  cursor: pointer;
}
.clickable ::v-deep {
  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
  }
}
</style>
