<template>
  <base-top-bar>
    <template
      v-slot:left
    >
      <b-nav-item to="user-profile">
        <i
          class="icon-user text-white"
        />
      </b-nav-item>
    </template>
    {{ todaysDate }}
    <template
      v-slot:right
    >
      <b-nav-item
        to="stock-management-search"
      >
        <i
          class="icon-magnifier text-white"
        />
      </b-nav-item>
    </template>
  </base-top-bar>
</template>

<script>
import moment from 'moment'
import { builtStrings } from '@/utils/strings'
import BaseTopBar from '../../navigation/BaseTopBar'

export default {
  name: 'StockManagementTopBar',
  components: { BaseTopBar },
  computed: {
    todaysDate () {
      return builtStrings.getPageStockTopBarDateMomentFormat({
        dateMoment: moment()
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
