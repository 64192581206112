<template>
  <b-row no-gutters class="w-100 pt-4">
    <b-col class="search-options-container">
      <search-box-multi
        v-model="searchParts"
        :hint-text="messages.getLabelSearchForStock()"
        :mandatory-tags="mandatoryTags"
        @input="handleSearch"
      />
      <search-options
        @change="change"
      />
    </b-col>
    <b-col class="d-flex justify-content-end">
      <!-- use v-if to hide calendar -->
      <b-calendar v-if="false" hide-header/>
    </b-col>
  </b-row>
</template>

<script>
/**
 * This is desktop view only panel containing:
 *   1. Free-text search bar
 *   2. Selectable filter buttons for all, arrived and incoming trackings.
 *   3. Calendar widget.
 */
import { messages } from '@/utils/strings'
import { MY_STOCK, MY_TEAM_STOCK } from '@/constants/stock-management'
import SearchBoxMulti from '@/components/FilterPanel/SearchBoxMulti'
import SearchOptions from './SearchOptions'

export default {
  name: 'FilterPanel', // FIXME: See that this is not confused with StockFilterMenu
  components: { SearchBoxMulti, SearchOptions },
  data () {
    return {
      // We need to use model and store the search text here as the SearchOptions
      // can change independently and the even payload does not contain the
      // free text search.
      // The searchParts in the Vuex store is updated by the action "search".
      searchParts: [],
      messages
    }
  },
  computed: {
    mandatoryTags () {
      const mandatoryTags = []
      const { type } = this.$store.getters['stockManagement/userSearchOptions']
      const { plants, plannerGroups, workCenters } = this.$store.state.profile

      if (plants?.length) {
        mandatoryTags.push(
          `plants=${JSON.stringify(plants)}`
        )
      }
      if ((type === MY_TEAM_STOCK || type === MY_STOCK) && plannerGroups?.length) {
        mandatoryTags.push(
          `plannerGroups=${JSON.stringify(plannerGroups)}`
        )
      }
      if (type === MY_STOCK && workCenters?.length) {
        mandatoryTags.push(
          `workCenters=${JSON.stringify(workCenters)}`
        )
      }
      return mandatoryTags
    }
  },
  methods: {
    handleSearch (value, performSearch) {
      if (performSearch) {
        this.change()
      }
    },
    /**
     * Handle user search event.
     *
     * @param {Object} event - Event payload from SearchBoxMulti or SearchOptions
     * @param {String} event.source - Source where the event was fired.
     * @param {any} event.value - Optional payload.
     */
    change (event) {
      // console.log({ _: 'FilterPanel.change', event, searchParts: this.searchParts })
      //
      // In practise we ignore the event source and payload as the end result is
      // the same: Make a new search.
      //
      // Because we are only passing the query parameter, the "stockManagement/search"
      // action is using the values stored in Vuex and updated by the SearchOptions
      // component.
      //
      // The searchParts is also stored in Vuex, but the "stockManagement/search" has
      // query as a required parameter. We could also use a Vuex getter to provide
      // that parameter.
      this.$store.dispatch('stockManagement/search', { query: this.searchParts })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
