import { MY_PLANT_STOCK, MY_TEAM_STOCK, MY_STOCK } from '@/constants/stock-management'
import { messages } from '@/utils/strings'

export default function getStockManagementButtons () {
  const buttons = [
    {
      label: messages.getPageStockTabMyStock(),
      value: MY_STOCK
    },
    {
      label: messages.getPageStockTabMyTeamStock(),
      value: MY_TEAM_STOCK
    },
    {
      label: messages.getPageStockTabMyPlantStock(),
      value: MY_PLANT_STOCK
    }
  ]
  return buttons
}
